import '../css/OptionsSelect.css'
import chevron from '../icn/chevron-down.svg'

export default function OptionsSelect({ id, disabled, question, placeholder, icon, variant, style, options, value, onChange }) {
  const placeholderOption = {
    disabled: true,
    hidden: true,
    text: placeholder,
    value: "placeholder",
  }
  options = [placeholderOption, ...options]
  return (
    <div className={`select ${variant}`} style={style}>
      { question && <p className="question">{ question }</p> }
      <label>
        <select
          name={id}
          style={{ paddingLeft: icon ? '56px' : '16px' }}
          value={value || "placeholder"}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
        >
          {
            options.map(opt => (
              <option
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled}
                hidden={opt.hidden}
              >{ opt.text }</option>
            ))
          }
        </select>
        <img className="chevron" src={chevron} alt={`open ${id} menu`} />
      </label>
    </div>
  )
}
