"use strict";
exports.__esModule = true;
exports.DefaultAuthService = exports.DefaultAssessmentService = exports.DefaultCheckoutService = exports.DefaultStudyAdminPortalService = void 0;
var twirp_1 = require("./twirp");
var ListRecordsRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        participant_user_id: m.participantUserId,
        record_types: m.recordTypes
    };
};
var JSONToAssessmentRecord = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: ((m.assessmentId) ? m.assessmentId : m.assessment_id),
        userId: ((m.userId) ? m.userId : m.user_id),
        participantUserId: ((m.participantUserId) ? m.participantUserId : m.participant_user_id),
        type: m.type,
        recordType: ((m.recordType) ? m.recordType : m.record_type),
        participantId: ((m.participantId) ? m.participantId : m.participant_id),
        isCompleted: ((m.isCompleted) ? m.isCompleted : m.is_completed),
        completed: m.completed,
        clinicianFirstName: ((m.clinicianFirstName) ? m.clinicianFirstName : m.clinician_first_name),
        clinicianLastName: ((m.clinicianLastName) ? m.clinicianLastName : m.clinician_last_name)
    };
};
var JSONToAssessmentRecords = function (m) {
    if (m === null) {
        return null;
    }
    return {
        records: m.records.map(JSONToAssessmentRecord)
    };
};
var StartAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        participant_user_id: m.participantUserId,
        type: m.type
    };
};
var JSONToStartAssessmentResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        participantUserId: ((m.participantUserId) ? m.participantUserId : m.participant_user_id),
        type: m.type,
        token: m.token,
        userId: ((m.userId) ? m.userId : m.user_id),
        link: m.link
    };
};
var UpdateParticipantStatusRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        user_id: m.userId,
        active: m.active
    };
};
var EmailPasswordResetRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        email: m.email
    };
};
var PasswordResetRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        key: m.key,
        old_password: m.oldPassword,
        password: m.password
    };
};
var CreateParticipantRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        email: m.email,
        site_id: m.siteId,
        study_id: m.studyId
    };
};
var UpdateParticipantEmailRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        user_id: m.userId,
        email: m.email
    };
};
var JSONToParticipant = function (m) {
    if (m === null) {
        return null;
    }
    return {
        userId: ((m.userId) ? m.userId : m.user_id),
        participantId: ((m.participantId) ? m.participantId : m.participant_id),
        participantNumber: ((m.participantNumber) ? m.participantNumber : m.participant_number),
        siteId: ((m.siteId) ? m.siteId : m.site_id),
        studyId: ((m.studyId) ? m.studyId : m.study_id),
        email: m.email,
        created: m.created,
        active: m.active,
        initialOutcome: ((m.initialOutcome) ? m.initialOutcome : m.initial_outcome),
        lastAssessment: ((m.lastAssessment) ? m.lastAssessment : m.last_assessment)
    };
};
var ListParticipantsRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        include_assessment: m.includeAssessment
    };
};
var JSONToListParticipantsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        participants: m.participants.map(JSONToParticipant),
        sites: m.sites.map(JSONToSite),
        studies: m.studies.map(JSONToStudy)
    };
};
var SiteToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        site_id: m.siteId,
        disabled: m.disabled,
        study_id: m.studyId,
        formatted_site_id: m.formattedSiteId
    };
};
var JSONToSite = function (m) {
    if (m === null) {
        return null;
    }
    return {
        siteId: ((m.siteId) ? m.siteId : m.site_id),
        disabled: m.disabled,
        studyId: ((m.studyId) ? m.studyId : m.study_id),
        formattedSiteId: ((m.formattedSiteId) ? m.formattedSiteId : m.formatted_site_id)
    };
};
var StudyToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        study_id: m.studyId,
        active: m.active,
        formatted_study_id: m.formattedStudyId
    };
};
var JSONToStudy = function (m) {
    if (m === null) {
        return null;
    }
    return {
        studyId: ((m.studyId) ? m.studyId : m.study_id),
        active: m.active,
        formattedStudyId: ((m.formattedStudyId) ? m.formattedStudyId : m.formatted_study_id)
    };
};
var CreateSapAccountRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        email: m.email,
        role: m.role,
        first_name: m.firstName,
        last_name: m.lastName
    };
};
var UpdateSapAccountRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        user_id: m.userId,
        email: m.email,
        role: m.role,
        first_name: m.firstName,
        last_name: m.lastName,
        active: m.active
    };
};
var JSONToSapAccount = function (m) {
    if (m === null) {
        return null;
    }
    return {
        userId: ((m.userId) ? m.userId : m.user_id),
        email: m.email,
        role: m.role,
        firstName: ((m.firstName) ? m.firstName : m.first_name),
        lastName: ((m.lastName) ? m.lastName : m.last_name),
        created: m.created,
        active: m.active
    };
};
var JSONToListSapAccountsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        sapAccounts: ((m.sapAccounts) ? m.sapAccounts : m.sap_accounts).map(JSONToSapAccount)
    };
};
var JSONToCreateUserResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        userId: ((m.userId) ? m.userId : m.user_id),
        active: m.active
    };
};
var EmailInviteRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        user_id: m.userId,
        email: m.email
    };
};
var JSONToEmailInviteResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        message: m.message
    };
};
var SapLoginRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        email: m.email,
        password: m.password
    };
};
var JSONToSapLoginResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        token: m.token,
        permissions: m.permissions,
        firstName: ((m.firstName) ? m.firstName : m.first_name),
        lastName: ((m.lastName) ? m.lastName : m.last_name)
    };
};
var JSONToOrder = function (m) {
    if (m === null) {
        return null;
    }
    return {
        orderId: ((m.orderId) ? m.orderId : m.order_id),
        assessmentId: ((m.assessmentId) ? m.assessmentId : m.assessment_id)
    };
};
var JSONToProduct = function (m) {
    if (m === null) {
        return null;
    }
    return {
        id: m.id,
        description: m.description,
        price: m.price
    };
};
var JSONToProducts = function (m) {
    if (m === null) {
        return null;
    }
    return {
        products: m.products.map(JSONToProduct)
    };
};
var PaymentMethodToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        id: m.id,
        last4: m.last4,
        brand: m.brand
    };
};
var JSONToPaymentMethod = function (m) {
    if (m === null) {
        return null;
    }
    return {
        id: m.id,
        last4: m.last4,
        brand: m.brand
    };
};
var JSONToCartCreated = function (m) {
    if (m === null) {
        return null;
    }
    return {
        cartId: ((m.cartId) ? m.cartId : m.cart_id),
        customerId: ((m.customerId) ? m.customerId : m.customer_id)
    };
};
var JSONToSetupIntent = function (m) {
    if (m === null) {
        return null;
    }
    return {
        customerId: ((m.customerId) ? m.customerId : m.customer_id),
        clientSecret: ((m.clientSecret) ? m.clientSecret : m.client_secret)
    };
};
var CreatePaymentIntentParamsToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        customer_id: m.customerId,
        payment_method: m.paymentMethod,
        product_id: m.productId
    };
};
var JSONToPaymentIntent = function (m) {
    if (m === null) {
        return null;
    }
    return {
        clientSecret: ((m.clientSecret) ? m.clientSecret : m.client_secret),
        subtotal: m.subtotal,
        shipping: m.shipping,
        tax: m.tax,
        amount: m.amount
    };
};
var JSONToClinicianAssessment = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: ((m.assessmentId) ? m.assessmentId : m.assessment_id),
        assessmentNumber: ((m.assessmentNumber) ? m.assessmentNumber : m.assessment_number)
    };
};
var ClinicianAssessmentTokenToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        token: m.token
    };
};
var TakeClinicianAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        token: m.token,
        metrics: m.metrics
    };
};
var JSONToTakeClinicianAssessmentResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: ((m.assessmentId) ? m.assessmentId : m.assessment_id),
        spec: m.spec,
        assessmentNumber: ((m.assessmentNumber) ? m.assessmentNumber : m.assessment_number),
        authToken: ((m.authToken) ? m.authToken : m.auth_token)
    };
};
var ReturnEditToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessment_id: m.assessmentId,
        screen_id: m.screenId,
        answers: m.answers
    };
};
var EmptyToJSON = function (_) {
    return {};
};
var JSONToEmpty = function (_) {
    return {};
};
var JSONToAssessmentNumberResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentid: ((m.assessmentid) ? m.assessmentid : m.assessmentId),
        assessmentnumber: ((m.assessmentnumber) ? m.assessmentnumber : m.assessmentNumber)
    };
};
var GetAssessmentsRequestToJSON = function (_) {
    return {};
};
var JSONToGetAssessmentsResponseItem = function (m) {
    if (m === null) {
        return null;
    }
    return {
        specid: ((m.specid) ? m.specid : m.specId),
        created: m.created,
        isdeployed: ((m.isdeployed) ? m.isdeployed : m.isDeployed),
        spec: m.spec
    };
};
var JSONToGetAssessmentsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        items: m.items.map(JSONToGetAssessmentsResponseItem)
    };
};
var TakeAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        specId: m.specid,
        assessmentId: m.assessmentid,
        metrics: m.metrics
    };
};
var JSONToTakeAssessmentResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentid: ((m.assessmentid) ? m.assessmentid : m.assessmentId),
        spec: m.spec,
        assessmentnumber: ((m.assessmentnumber) ? m.assessmentnumber : m.assessmentNumber)
    };
};
var JSONToAssessmentState = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: m.state,
        index: m.index,
        kickoutreason: ((m.kickoutreason) ? m.kickoutreason : m.kickoutReason),
        iscompleted: ((m.iscompleted) ? m.iscompleted : m.isCompleted),
        completed: m.completed,
        answers: m.answers,
        outcome: m.outcome,
        editindex: ((m.editindex) ? m.editindex : m.editIndex)
    };
};
var ScreenStateToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        answers: m.answers
    };
};
var SubmitScreenRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        screenState: ScreenStateToJSON(m.screenstate)
    };
};
var JSONToSubmitScreenResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state)
    };
};
var EditScreenRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        index: m.index
    };
};
var JSONToEditScreenResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state)
    };
};
var GoBackRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid
    };
};
var JSONToGoBackResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state)
    };
};
var GetStateRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid
    };
};
var JSONToGetStateResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state),
        spec: m.spec,
        assessmentnumber: ((m.assessmentnumber) ? m.assessmentnumber : m.assessmentNumber),
        recordType: ((m.recordType) ? m.recordType : m.record_type)
    };
};
var GetSummaryRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid
    };
};
var JSONToGetSummaryResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        summaryids: ((m.summaryids) ? m.summaryids : m.summaryIds)
    };
};
var RecordMetricRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        data: m.data
    };
};
var JSONToRecordMetricResponse = function (_) {
    return {};
};
var RecordEventRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        eventType: m.eventtype,
        data: m.data
    };
};
var JSONToRecordEventResponse = function (_) {
    return {};
};
var FinishAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        recordId: m.recordid
    };
};
var JSONToFinishAssessmentResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state)
    };
};
var GetRolesRequestToJSON = function (_) {
    return {};
};
var JSONToGetRolesResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        roles: m.roles
    };
};
var GetPermissionsRequestToJSON = function (_) {
    return {};
};
var JSONToGetPermissionsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        permissions: m.permissions
    };
};
var DefaultStudyAdminPortalService = /** @class */ (function () {
    function DefaultStudyAdminPortalService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.StudyAdminPortalService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultStudyAdminPortalService.prototype.login = function (sapLoginRequest) {
        var url = this.hostname + this.pathPrefix + "Login";
        var body = sapLoginRequest;
        if (!this.writeCamelCase) {
            body = SapLoginRequestToJSON(sapLoginRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToSapLoginResponse);
        });
    };
    DefaultStudyAdminPortalService.prototype.reSendEmailInvite = function (emailInviteRequest) {
        var url = this.hostname + this.pathPrefix + "ReSendEmailInvite";
        var body = emailInviteRequest;
        if (!this.writeCamelCase) {
            body = EmailInviteRequestToJSON(emailInviteRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEmailInviteResponse);
        });
    };
    DefaultStudyAdminPortalService.prototype.createSapAccount = function (createSapAccountRequest) {
        var url = this.hostname + this.pathPrefix + "CreateSapAccount";
        var body = createSapAccountRequest;
        if (!this.writeCamelCase) {
            body = CreateSapAccountRequestToJSON(createSapAccountRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToCreateUserResponse);
        });
    };
    DefaultStudyAdminPortalService.prototype.updateSapAccount = function (updateSapAccountRequest) {
        var url = this.hostname + this.pathPrefix + "UpdateSapAccount";
        var body = updateSapAccountRequest;
        if (!this.writeCamelCase) {
            body = UpdateSapAccountRequestToJSON(updateSapAccountRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToSapAccount);
        });
    };
    DefaultStudyAdminPortalService.prototype.reSendPasswordResetEmail = function (emailPasswordResetRequest) {
        var url = this.hostname + this.pathPrefix + "ReSendPasswordResetEmail";
        var body = emailPasswordResetRequest;
        if (!this.writeCamelCase) {
            body = EmailPasswordResetRequestToJSON(emailPasswordResetRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEmpty);
        });
    };
    DefaultStudyAdminPortalService.prototype.resetAccountPassword = function (passwordResetRequest) {
        var url = this.hostname + this.pathPrefix + "ResetAccountPassword";
        var body = passwordResetRequest;
        if (!this.writeCamelCase) {
            body = PasswordResetRequestToJSON(passwordResetRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEmpty);
        });
    };
    DefaultStudyAdminPortalService.prototype.listSapAccounts = function (empty) {
        var url = this.hostname + this.pathPrefix + "ListSapAccounts";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToListSapAccountsResponse);
        });
    };
    DefaultStudyAdminPortalService.prototype.createParticipant = function (createParticipantRequest) {
        var url = this.hostname + this.pathPrefix + "CreateParticipant";
        var body = createParticipantRequest;
        if (!this.writeCamelCase) {
            body = CreateParticipantRequestToJSON(createParticipantRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToParticipant);
        });
    };
    DefaultStudyAdminPortalService.prototype.updateParticipantEmail = function (updateParticipantEmailRequest) {
        var url = this.hostname + this.pathPrefix + "UpdateParticipantEmail";
        var body = updateParticipantEmailRequest;
        if (!this.writeCamelCase) {
            body = UpdateParticipantEmailRequestToJSON(updateParticipantEmailRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToParticipant);
        });
    };
    DefaultStudyAdminPortalService.prototype.updateParticipantStatus = function (updateParticipantStatusRequest) {
        var url = this.hostname + this.pathPrefix + "UpdateParticipantStatus";
        var body = updateParticipantStatusRequest;
        if (!this.writeCamelCase) {
            body = UpdateParticipantStatusRequestToJSON(updateParticipantStatusRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToParticipant);
        });
    };
    DefaultStudyAdminPortalService.prototype.listParticipants = function (listParticipantsRequest) {
        var url = this.hostname + this.pathPrefix + "ListParticipants";
        var body = listParticipantsRequest;
        if (!this.writeCamelCase) {
            body = ListParticipantsRequestToJSON(listParticipantsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToListParticipantsResponse);
        });
    };
    DefaultStudyAdminPortalService.prototype.listRecords = function (listRecordsRequest) {
        var url = this.hostname + this.pathPrefix + "ListRecords";
        var body = listRecordsRequest;
        if (!this.writeCamelCase) {
            body = ListRecordsRequestToJSON(listRecordsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToAssessmentRecords);
        });
    };
    DefaultStudyAdminPortalService.prototype.updateStudySite = function (site) {
        var url = this.hostname + this.pathPrefix + "UpdateStudySite";
        var body = site;
        if (!this.writeCamelCase) {
            body = SiteToJSON(site);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEmpty);
        });
    };
    DefaultStudyAdminPortalService.prototype.updateStudy = function (study) {
        var url = this.hostname + this.pathPrefix + "UpdateStudy";
        var body = study;
        if (!this.writeCamelCase) {
            body = StudyToJSON(study);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEmpty);
        });
    };
    DefaultStudyAdminPortalService.prototype.startAssessment = function (startAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "StartAssessment";
        var body = startAssessmentRequest;
        if (!this.writeCamelCase) {
            body = StartAssessmentRequestToJSON(startAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToStartAssessmentResponse);
        });
    };
    return DefaultStudyAdminPortalService;
}());
exports.DefaultStudyAdminPortalService = DefaultStudyAdminPortalService;
var DefaultCheckoutService = /** @class */ (function () {
    function DefaultCheckoutService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.CheckoutService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultCheckoutService.prototype.getProducts = function (empty) {
        var url = this.hostname + this.pathPrefix + "GetProducts";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToProducts);
        });
    };
    DefaultCheckoutService.prototype.createCart = function (empty) {
        var url = this.hostname + this.pathPrefix + "CreateCart";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToCartCreated);
        });
    };
    DefaultCheckoutService.prototype.createSetupIntent = function (empty) {
        var url = this.hostname + this.pathPrefix + "CreateSetupIntent";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToSetupIntent);
        });
    };
    DefaultCheckoutService.prototype.getPaymentMethod = function (paymentMethod) {
        var url = this.hostname + this.pathPrefix + "GetPaymentMethod";
        var body = paymentMethod;
        if (!this.writeCamelCase) {
            body = PaymentMethodToJSON(paymentMethod);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToPaymentMethod);
        });
    };
    DefaultCheckoutService.prototype.createPaymentIntent = function (createPaymentIntentParams) {
        var url = this.hostname + this.pathPrefix + "CreatePaymentIntent";
        var body = createPaymentIntentParams;
        if (!this.writeCamelCase) {
            body = CreatePaymentIntentParamsToJSON(createPaymentIntentParams);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToPaymentIntent);
        });
    };
    DefaultCheckoutService.prototype.placeOrder = function (empty) {
        var url = this.hostname + this.pathPrefix + "PlaceOrder";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToOrder);
        });
    };
    return DefaultCheckoutService;
}());
exports.DefaultCheckoutService = DefaultCheckoutService;
var DefaultAssessmentService = /** @class */ (function () {
    function DefaultAssessmentService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.AssessmentService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultAssessmentService.prototype.getAssessments = function (getAssessmentsRequest) {
        var url = this.hostname + this.pathPrefix + "GetAssessments";
        var body = getAssessmentsRequest;
        if (!this.writeCamelCase) {
            body = GetAssessmentsRequestToJSON(getAssessmentsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetAssessmentsResponse);
        });
    };
    DefaultAssessmentService.prototype.takeAssessment = function (takeAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "TakeAssessment";
        var body = takeAssessmentRequest;
        if (!this.writeCamelCase) {
            body = TakeAssessmentRequestToJSON(takeAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToTakeAssessmentResponse);
        });
    };
    DefaultAssessmentService.prototype.takeClinicianAssessment = function (takeClinicianAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "TakeClinicianAssessment";
        var body = takeClinicianAssessmentRequest;
        if (!this.writeCamelCase) {
            body = TakeClinicianAssessmentRequestToJSON(takeClinicianAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToTakeClinicianAssessmentResponse);
        });
    };
    DefaultAssessmentService.prototype.getClinicianAssessment = function (clinicianAssessmentToken) {
        var url = this.hostname + this.pathPrefix + "GetClinicianAssessment";
        var body = clinicianAssessmentToken;
        if (!this.writeCamelCase) {
            body = ClinicianAssessmentTokenToJSON(clinicianAssessmentToken);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToClinicianAssessment);
        });
    };
    DefaultAssessmentService.prototype.takeReassessment = function (takeAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "TakeReassessment";
        var body = takeAssessmentRequest;
        if (!this.writeCamelCase) {
            body = TakeAssessmentRequestToJSON(takeAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToTakeAssessmentResponse);
        });
    };
    DefaultAssessmentService.prototype.submitScreen = function (submitScreenRequest) {
        var url = this.hostname + this.pathPrefix + "SubmitScreen";
        var body = submitScreenRequest;
        if (!this.writeCamelCase) {
            body = SubmitScreenRequestToJSON(submitScreenRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToSubmitScreenResponse);
        });
    };
    DefaultAssessmentService.prototype.editScreen = function (editScreenRequest) {
        var url = this.hostname + this.pathPrefix + "EditScreen";
        var body = editScreenRequest;
        if (!this.writeCamelCase) {
            body = EditScreenRequestToJSON(editScreenRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEditScreenResponse);
        });
    };
    DefaultAssessmentService.prototype.goBack = function (goBackRequest) {
        var url = this.hostname + this.pathPrefix + "GoBack";
        var body = goBackRequest;
        if (!this.writeCamelCase) {
            body = GoBackRequestToJSON(goBackRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGoBackResponse);
        });
    };
    DefaultAssessmentService.prototype.getState = function (getStateRequest) {
        var url = this.hostname + this.pathPrefix + "GetState";
        var body = getStateRequest;
        if (!this.writeCamelCase) {
            body = GetStateRequestToJSON(getStateRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetStateResponse);
        });
    };
    DefaultAssessmentService.prototype.getSummary = function (getSummaryRequest) {
        var url = this.hostname + this.pathPrefix + "GetSummary";
        var body = getSummaryRequest;
        if (!this.writeCamelCase) {
            body = GetSummaryRequestToJSON(getSummaryRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetSummaryResponse);
        });
    };
    DefaultAssessmentService.prototype.recordMetric = function (recordMetricRequest) {
        var url = this.hostname + this.pathPrefix + "RecordMetric";
        var body = recordMetricRequest;
        if (!this.writeCamelCase) {
            body = RecordMetricRequestToJSON(recordMetricRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToRecordMetricResponse);
        });
    };
    DefaultAssessmentService.prototype.recordEvent = function (recordEventRequest) {
        var url = this.hostname + this.pathPrefix + "RecordEvent";
        var body = recordEventRequest;
        if (!this.writeCamelCase) {
            body = RecordEventRequestToJSON(recordEventRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToRecordEventResponse);
        });
    };
    DefaultAssessmentService.prototype.finishAssessment = function (finishAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "FinishAssessment";
        var body = finishAssessmentRequest;
        if (!this.writeCamelCase) {
            body = FinishAssessmentRequestToJSON(finishAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToFinishAssessmentResponse);
        });
    };
    DefaultAssessmentService.prototype.reserveAssessmentNumber = function (empty) {
        var url = this.hostname + this.pathPrefix + "ReserveAssessmentNumber";
        var body = empty;
        if (!this.writeCamelCase) {
            body = EmptyToJSON(empty);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToAssessmentNumberResponse);
        });
    };
    DefaultAssessmentService.prototype.returningEdit = function (returnEdit) {
        var url = this.hostname + this.pathPrefix + "ReturningEdit";
        var body = returnEdit;
        if (!this.writeCamelCase) {
            body = ReturnEditToJSON(returnEdit);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToEditScreenResponse);
        });
    };
    return DefaultAssessmentService;
}());
exports.DefaultAssessmentService = DefaultAssessmentService;
var DefaultAuthService = /** @class */ (function () {
    function DefaultAuthService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.AuthService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultAuthService.prototype.getRoles = function (getRolesRequest) {
        var url = this.hostname + this.pathPrefix + "GetRoles";
        var body = getRolesRequest;
        if (!this.writeCamelCase) {
            body = GetRolesRequestToJSON(getRolesRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetRolesResponse);
        });
    };
    DefaultAuthService.prototype.getPermissions = function (getPermissionsRequest) {
        var url = this.hostname + this.pathPrefix + "GetPermissions";
        var body = getPermissionsRequest;
        if (!this.writeCamelCase) {
            body = GetPermissionsRequestToJSON(getPermissionsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetPermissionsResponse);
        });
    };
    return DefaultAuthService;
}());
exports.DefaultAuthService = DefaultAuthService;
