import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"

import logo from '../img/zena.png'
import { sapClient } from '../client'
import Nav from '../components/Nav'
import { useHasPermissions } from '../components/Protected'
import Layout from '../pages/Layout'

function HeaderBar() {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    color: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    minHeight: "56px",
    padding: "0 64px",
    alignItems: "center",
  }

  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    color: "rgb(101, 101, 101)"
  }
  return (
    <div style={style}>
      {/* <img src={logo} alt="" style={{position: "absolute", left: "50%", transform: "translateX(-50%)", height: "80%"}} /> */}
      <Link to="/logout" style={linkStyle}>Logout</Link>
    </div>
  )
}

export default function Dashboard() {

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
