import zena from '../img/zena.png'
import flare from '../img/zenaflare.png'
import { colors } from '../components/styles'
import { useHasPermissions } from '../components/Protected'
import { Link, NavLink } from 'react-router-dom' 
import { useSelector } from 'react-redux'

function TopBar({authed, firstName, lastName}) {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 64px",
    alignItems: "center",
    height: "60px",
    background: colors.offwhite,
  }

  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    color: colors.purple,
  }
  return (
    <div style={style}>
      {authed && (
        <div style={{display: "flex", gap: "32px"}}>
          <span style={{...linkStyle, color: colors.greyText}}>{firstName} {lastName}</span>
          <Link to="/logout" style={linkStyle}>Log out</Link>
        </div>
      )}
    </div>
  )
}

function NavMenu() {
  const hasPermissions = useHasPermissions()

  const style = {
    display: "flex",
    flexDirection: "column",
  }

  const links = [
    {to: "/dashboard/participants", text: "Participants"},
    {to: "/dashboard/accounts", text: "Accounts", every: false, permissions: [
      "users.centralAssessors.edit",
      "users.studyStaffLeads.edit",
      "users.studyStaff.edit",
      "users.clinicianLeads.edit",
      "users.clinicians.edit",
    ]},
    {to: "/dashboard/records", text: "Records", every: false, permissions: ["assessments.participantRecords.generateCsv", "assessments.clinicianRecords.generateCsv"]},
    {to: "/dashboard/studies", text: "Studies", permissions: ["admins.sites.edit"]},
    {to: "/dashboard/sites", text: "Sites", permissions: ["admins.sites.edit"]},
    {to: "/dashboard/my-password", text: "My Password"},
  ]

  const visibleLinks = links.filter(x => hasPermissions(x.permissions, x.every ?? true))

  return (
    <div style={style}>
      {visibleLinks.map((link, i) => (
        <NavLink key={i} to={link.to} style={({ isActive }) => {
          const style = {
            color: "white",
            textDecoration: "none",
            padding: "16px 32px",
            borderLeft: "4px solid rgb(140, 62, 123)",
          }
          if (isActive) {
            return {
              ...style,
              background: "rgb(70, 66, 141)",
              borderLeft: "4px solid white", 
            }
          }
          return style
        }}>
          {link.text}
        </NavLink>
      ))}
    </div>
  )
}

function VerticalBar({authed}) {
  const style = {
    width: "295px",
  
    flex: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    background: `url(${flare}), linear-gradient(90deg, rgba(80,62,123,1) 10%, rgba(68,67,144,1) 100%)`,
    backgroundRepeat: "no-repeat, repeat",
    backgroundSize: "100%",
    backgroundPosition: "0 100%, 0 0",
    backgroundColor: "rgb(77, 64, 139)",
  }
  return (
    <div style={style}>
      <img src={zena} alt="" style={{width: "155px", marginTop: "134px"}} />
      <span style={{color: "white", marginTop: "12px"}}>SYSTEM ADMINISTRATION</span>
      {authed && (
        <div style={{alignSelf: "stretch", marginTop: "48px"}}>
          <NavMenu />
        </div>
      )}
    </div>
  )
}

function Content({children}) {
  const style = {
    background: "rgb(245, 243, 255)",
    width: "100%",
    padding: "36px 168px"
  }
  return (
    <div style={style}>{children}</div>
  )
}

export default function Layout({children, spaced=true}) {
  const {authed, firstName, lastName} = useSelector(state => state.auth)

  const style = {
    display: "flex",
    flexDirection: "column"
  }
  return (
    <div className='page' style={style}>
      <TopBar authed={authed} firstName={firstName} lastName={lastName}  />
      <div style={{display: "flex", flex: "auto"}}>
        <div style={{display:"flex", flexDirection: "column"}}>
          <VerticalBar authed={authed} />
        </div>
        <div style={{display:"flex", flex: "auto"}}>
          <Content children={children} spaced={spaced} />
        </div>
      </div>
    </div>
  )
}
