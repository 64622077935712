import ReactToggle from 'react-toggle'

export default function Toggle({id, label, checked, onChange}) {
  return (
    <div>
      {label && <label htmlFor={id} style={{display: "block", marginBottom: "16px"}}>{label}</label>}
      <ReactToggle
        id={id}
        checked={checked}
        onChange={onChange} />
    </div>
  )
}
