import { useEffect, useMemo, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import Table from '../components/Table';
import Nav from "../components/Nav";
import Modal from '../components/Modal';
import { sapClient } from "../client";

function buildMap(key, xs) {
  const ret = {}
  for (const x of xs) {
    ret[x[key]] = x
  }
  return ret
}

export default function Accounts() {
  const [sites, setSites] = useState({})
  const { permissions } = useSelector(x => x.auth)
  const [showModal, setShowModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState()

  useEffect(() => {
    sapClient.listParticipants({})
      .then(res => setSites(buildMap("siteId", res.sites)))
  }, [])

  const updateSite = site => {
    setSites({...sites, [site.siteId]: site})
    sapClient.updateStudySite(site)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Site ID",
        accessor: "siteId",
        width: 120,
        Cell: (cell) => cell.row.original.formattedSiteId
      },
      {
        Header: "Active",
        accessor: "disabled",
        Cell: (cell) => {
          const value = cell.row.values.siteId
          return (
            <Toggle
              checked={!sites[value].disabled}
              onChange={e => updateSite({...sites[value], disabled: !e.target.checked})}/>
          )
        },
        width: 120,
      },
    ],
    [sites]
  );

  return (
    <div>
      <Table columns={columns} data={Object.values(sites)}/>
    </div>
  )
}
