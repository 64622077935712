import { colors } from "./styles"

export default function Card({title, children}) {
  const style = {
    display: "inline-block",
    padding: "48px 64px",
    border: "1px solid var(--black-16)",
    background: "white",
    borderRadius: "5px"
  }
  return (
      <div style={style}>
        <div style={{display: "flex", flexDirection: "column", gap: "16px", width: "300px"}}>
          {title && (
            <div style={{textAlign: "center", marginBottom: "16px", fontWeight: "bold", color: colors.green}}>
              <h1 style={{margin: 0}}>{title}</h1>
            </div>
          )}
          {children}
        </div>
      </div>
  )
}
