import { useState } from 'react'
import { LoadingButton } from '../components/Button'
import RadioList from '../components/RadioList'
import { httpClient } from '../client'
import download from 'downloadjs'
import { toast } from 'react-toastify'
import { useHasPermissions } from '../components/Protected'
import Card from '../components/Card'

export default function DownloadRecords() {
  const [value, setValue] = useState('')
  const hasPermissions = useHasPermissions()

  const options = [
    {value: 'participants', label: 'Participant Records', recordTypes: ['participant'], permissions: [
      'assessments.participantRecords.generateCsv'
    ]},
    {value: 'clinicians', label: 'Clinician Records', recordTypes: ['clinician'], permissions: [
      'assessments.clinicianRecords.generateCsv'
    ]},
    {value: 'participants-clinicians', label: 'Participant and Clinician Records', recordTypes: ['participant', 'clinician'], permissions: [
      'assessments.participantRecords.generateCsv',
      'assessments.clinicianRecords.generateCsv'
    ]}
  ]

  for (const option of options) {
    option.disabled = !hasPermissions(option.permissions)
  }

  const selected = options.find(x => x.value === value)

  return (
      <div style={{width: "min(100%, 400px)"}}>
        <h1>Download Records</h1>
        <p>Download assessments and order history csv files for all participants and clinicians</p>

        <Card>
          <div>
            <div style={{marginBottom: "16px"}}>Select role:</div>
            <RadioList 
              name="records"
              value={value}
              onChange={setValue}
              options={options}
            />
          </div>
          
          <LoadingButton onClick={async (unlock) => {
            try {
              const resp = await httpClient.downloadCsv({recordTypes: selected.recordTypes})
              if (resp.ok) {
                const blob = await resp.blob()
                download(blob, "records.csv", "text/csv")
              } else {
                throw new Error("error downloading csv")
              }
            } catch (e) {
              console.error(e)
              toast.error("an error occurred")
            } finally {
              unlock()
            }
          }} children={["Download CSV"]} disabled={!value} />
        </Card>
      </div>
  )
}
