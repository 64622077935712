import { Link as RouterLink } from "react-router-dom"
import { colors } from "./styles"

export default function Link({style, ...rest}) {
  style = {
    textDecoration: "none",
    color: colors.purple,
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    ...style
  }
  return <RouterLink style={style} {...rest} />
}
