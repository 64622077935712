import { useEffect, useMemo, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import Table from '../components/Table';
import Nav from "../components/Nav";
import Modal from '../components/Modal';
import { sapClient } from "../client";

function buildMap(key, xs) {
  const ret = {}
  for (const x of xs) {
    ret[x[key]] = x
  }
  return ret
}

export default function Accounts() {
  const [studies, setStudies] = useState({})
  const { permissions } = useSelector(x => x.auth)
  const [showModal, setShowModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState()

  useEffect(() => {
    sapClient.listParticipants({})
      .then(res => setStudies(buildMap("studyId", res.studies)))
  }, [])

  const updateStudy = study => {
    setStudies({...studies, [study.studyId]: study})
    sapClient.updateStudy(study)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Study ID",
        accessor: "studyId",
        width: 120,
        Cell: (cell) => cell.row.original.formattedStudyId
      },
      {
        Header: "Active",
        accessor: "disabled",
        Cell: (cell) => {
          const value = cell.row.values.studyId
          return (
            <Toggle
              checked={studies[value].active}
              onChange={e => updateStudy({...studies[value], active: e.target.checked})}/>
          )
        },
        width: 120,
      },
    ],
    [studies]
  )  

  return (
    <div>
      <Table columns={columns} data={Object.values(studies)}/>
    </div>
  )
}
