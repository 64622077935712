import { useState } from "react"
import Card from "../components/Card"
import TextInput from '../components/TextInput'
import { LoadingButton } from '../components/Button'
import { sapClient } from "../client"
import { toast } from "react-toastify"

export default function MyPassword() {
  const [old, setOld] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [errMsg, setErrMsg] = useState("")

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  return (
      <Card title="Change My Password">
        <TextInput id="old" type="password" label="Old Password" value={old} onChange={setOld} />
        <TextInput id="new" type="password" label="New Password" value={password} onChange={setPassword} />
        <TextInput id="con" type="password" label="Confirm New Password" value={confirm} onChange={setConfirm} />
        
        <LoadingButton
          children={"Save"}
          style={{marginTop: "32px"}}
          disabled={(!old || !password || !confirm) || (password !== confirm)}
          onClick={async (unlock) => {
            setErrMsg("")

            try {
              await sapClient.resetAccountPassword({oldPassword: old, password})
              toast.success("password saved")
              setOld("")
              setPassword("")
              setConfirm("")
            } catch (e) {
              toast.error("could not change password")
              if (e.meta.reason) {
                setErrMsg(e.meta.reason)
              }
              console.error(e)
            } finally {
              unlock()
            }
          }} />

          {errMsg && <div style={{color: "red", whiteSpace: "pre-line", fontSize: "12px"}}>{errMsg}</div>}
      </Card>
  )
}
