import { useSelector } from "react-redux"

export function hasPermissions(needs, has, every=true) {
  if (!needs) { return true }

  if (every) {
    return needs.every(needed => has.includes(needed))
  }
  return needs.some(needed => has.includes(needed))
}

export function useHasPermissions() {
  const has = useSelector(state => state.auth.permissions)
  return (needs, every=true) => hasPermissions(needs, has, every)
}

export default function Protected({children, needs, every=true}) {
  const _hasPermissions = useHasPermissions()
  if (!_hasPermissions(needs, every)) {
    return null
  }
  return children
}
