import { useState } from 'react'
import TextInput from '../components/TextInput'
import { LoadingButton } from '../components/Button'
import { sapClient } from '../client'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams, Navigate } from "react-router-dom"
import { toast } from 'react-toastify'
import Card from '../components/Card'
import DesktopLayout from './Layout'
import Link from '../components/Link'
import { useSelector } from 'react-redux'

export default function Page() {
  const [searchParams, _] = useSearchParams()
  const authed = useSelector(state => state.auth.authed)

  const forgot = searchParams.get("forgot")
  const reset = searchParams.get("reset")

  if (authed) {
    return <Navigate to="/dashboard/participants" />
  }
  
  return (
    <DesktopLayout>
      {
        (() => {
          if (forgot) {
            return <ForgotPassword />
          } else if (reset) {
            return <ResetPassword token={reset} />
          } else {
            return <Login />
          }
        })()
      }
    </DesktopLayout>
  )
}

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <Card title="Log In">
        <TextInput id="email" label="Email:" value={email} onChange={v => setEmail(v)} />
        <TextInput id="password" label="Password:" type="password" value={password} onChange={v => setPassword(v)} />
        <Link to="/?forgot=1">Forgot Password?</Link>
        <LoadingButton disabled={!email || !password} onClick={async (unlock) => {
          try {
            const resp = await sapClient.login({ email, password })
            const { token, permissions, firstName, lastName } = resp
            sessionStorage.setItem('sap_token', JSON.stringify({token, permissions, firstName, lastName}))    
            dispatch({ type: 'auth/login_success', token, permissions, firstName, lastName})
            navigate('/dashboard/participants', { replace: true })
          } catch (e) {
            toast.error('an error occurred')
            console.error(e)
          } finally {
            unlock()
          }
        }}>
          Sign In
        </LoadingButton>
    </Card>
  )
}

export function ForgotPassword() {
  const [email, setEmail] = useState('')
  return (
    <Card title="Forgot Password">
      <p className='nomargin'>Enter your email address and a link will be sent to reset your password:</p>
      <TextInput id="email" label="Email:" value={email} onChange={v => setEmail(v)} />
      <LoadingButton disabled={!email} onClick={async (unlock) => {
        try {
          await sapClient.reSendPasswordResetEmail({email})
          toast.success('email sent')
        } catch (e) {
          toast.error('an error occurred')
          console.error(e)
        } finally {
          unlock()
        }
      }}>
        Submit
      </LoadingButton>
      <Link to="/">Go back to login page</Link>
    </Card>
  )
}

export function ResetPassword({token}) {
  const [password, setPassword] = useState('')

  const [err, setErr] = useState('')

  return (
    <Card title="Reset Password">
      {
        token ? (
          <>
            <TextInput id="password" label="New password:" type="password" value={password} onChange={v => setPassword(v)} />
            {err && <div style={{color: "red", whiteSpace: "pre-line", fontSize: "12px"}}>{err}</div>}
            <LoadingButton disabled={!password} onClick={async (unlock) => {
              try {
                await sapClient.resetAccountPassword({key: token, password})
                toast.success('password reset successfully')
                setPassword('')
                setErr('')
              } catch (e) {
                if (e.meta.reason) {
                  setErr(e.meta.reason)
                } else {
                  toast.error('an error occurred')
                  console.error(e)
                }
              } finally {
                unlock()
              }
            }}>
              Submit
            </LoadingButton>
            <Link to="/">Go back to login page</Link>
          </>
        ) : (
          <div>missing token</div>
        )
      }
    </Card>
  )
}
