import { useMemo, useState, useEffect } from "react"
import Table from "../components/Table"
import { sapClient } from "../client"
import TextInput from "../components/TextInput"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Deactivated } from "./Accounts"
import Toggle from '../components/Toggle'
import { Label } from './Accounts'
import { LoadingButton, Button, PrimaryOutline, Secondary } from "../components/Button"
import Modal, {FullScreenModal } from "../components/Modal"
import Protected, { useHasPermissions } from "../components/Protected"
import { httpClient } from '../client'
import download from 'downloadjs'
import { useSearchParams } from "react-router-dom"
import OptionsSelect from '../components/OptionsSelect'

function ViewRecordsForParticipant({participant, onClose}) {
  const [showingModal, setShowingModal] = useState(false)
  const [records, setRecords] = useState([])
  const hasPermissions = useHasPermissions()
  const [selected, setSelected] = useState([])

  const columns = useMemo(
    () => [
      {
        id: "select",
        Header: "Select",
        width: "50px",
        Cell: (cell) => {
          const onChange = e => {
            if (e.target.checked) {
              setSelected(selected => [...selected, cell.row.original.assessmentId])
            } else {
              setSelected(selected => selected.filter(x => x !== cell.row.original.assessmentId))
            }
          }
          return (
            <input type="checkbox" checked={selected.includes(cell.row.original.assessmentId)} onChange={onChange} />
          )
        }
      },
      {
        Header: "Assessment Type",
        accessor: "type",
        width: 150,
      },
      // {
      //   Header: "Record Type",
      //   accessor: "recordType",
      //   width: 150,
      // },
      {
        Header: "Completed By",
        accessor: "participantId",
        width: 150,
        Cell: (cell) => {
          const original = cell.row.original
          if (original.recordType === "clinician") {
            return original.clinicianFirstName + " " + original.clinicianLastName
          }
          return cell.row.original.participantId
        }
      },
      {
        Header: "Completed Date",
        accessor: "completed",
        width: 250,
        Cell: (cell) => {
          if (!cell.row.original.isCompleted) {
            return "-"
          }
          return new Date(cell.value * 1000).toUTCString()
        } 
      },
    ],
    [selected]
  )

  const viewable = []
  if (hasPermissions(["assessments.participantRecords.view"])) {
    viewable.push("participant")
  }

  if (hasPermissions(["assessments.clinicianRecords.view"])) {
    viewable.push("clinician")
  }

  async function refreshRecords() {
    if (!participant) {
      return []
    }
    const resp = await sapClient.listRecords({participantUserId: participant.userId, recordTypes: viewable})
    setRecords(resp.records)
  }

  useEffect(() => {
    refreshRecords()
  }, [])

  const data = records

  const canExport = hasPermissions([
    "assessments.participantRecords.generateSelectCsv",
    "assessments.clinicianRecords.generateSelectCsv",
  ], false)

  return (
    <div>
      <Modal isOpen={showingModal} onRequestClose={() => setShowingModal(false)}>
        <div style={{minHeight: "inherit", display: "flex", flexDirection: "column"}}>
          <h2>Add Record</h2>
          <div style={{flex: "auto"}}>Participant: {participant.participantId}</div>
          <div style={{display: "flex", gap: "16px"}}>
            <Button onClick={() => setShowingModal(false)}>Cancel</Button>
            <LoadingButton onClick={async (unlock) => {
              try {
                const resp = await sapClient.startAssessment({participantUserId: participant.userId, type: "initial"})
                const tab = window.open(resp.link, "clinicianAssessmentTab")
                const interval = setInterval(() => {
                  if (tab.closed) {
                    refreshRecords()
                    clearInterval(interval)
                  }
                }, 100)
                setShowingModal(false)
              } catch (e) {
                console.error(e)
                toast.error("an error occurred")
              } finally {
                unlock()
              }
            }}>Save</LoadingButton>        
          </div>
        </div>
      </Modal>
      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{width: "min(100%, 900px)", display: "flex", flexDirection: "column", gap: "32px"}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "32px"}}>
            <div>
              <h1>Participant: {participant.participantId}</h1>
              <span>Email: {participant.email}</span>
            </div>
            <PrimaryOutline style={{display: "flex", gap: "16px", alignItems: "center", width: "initial"}} onClick={onClose}>
              Close Record
            </PrimaryOutline>
          </div>
          <div style={{display: "flex", justifyContent: "center", gap: "16px"}}>
            <Protected needs={["assessments.clinicianRecords.add"]}>
              <Button onClick={() => setShowingModal(true)}>Add Record</Button>
            </Protected>
            {canExport && (
              <LoadingButton disabled={!selected.length} onClick={async (unlock) => {
                try {
                  const resp = await httpClient.exportAssessments({assessmentIds: selected})
                  if (resp.ok) {
                    const blob = await resp.blob()
                    download(blob, "records.csv", "text/csv")
                  } else {
                    throw new Error("response not ok")
                  }
                } catch (e) {
                  console.error(e)
                  toast.error("an error occurred")
                } finally {
                  unlock()
                }
              }}>
                Download CSV
              </LoadingButton>
            )}
          </div>
          <div>
            <Table
              columns={columns}
              data={data} 
              initialState={{hiddenColumns: !canExport ? ["select"] : []}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export function ViewRecordFullscreenModal({participant, isOpen, onClose}) {
  return (
    <FullScreenModal isOpen={isOpen}>
      {participant && <ViewRecordsForParticipant participant={participant} onClose={onClose} />}
    </FullScreenModal>
  )
}

function AddModalContent({ siteIds, studyIds, onConfirm, onCancel }) {
  const [email, setEmail] = useState("")
  const [siteId, setSiteId] = useState("")
  const [studyId, setStudyId] = useState("")
  const [errorMsg, setErrorMsg] = useState("")

  // error handling
  const handleConfirm = async () => {
    try {
      await onConfirm({ email, siteId, studyId })
      setErrorMsg("")
    } catch (error) {
      if (error.message) {
        console.error(error)
        setErrorMsg(error.message)
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.msg
      ) {
        setErrorMsg(error.response.data.msg)
      } else {
        setErrorMsg("An error occurred.")
      }
    }
  }

  return (
    <div>
      <h2>Add Participant</h2>

      <div style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "48px"}}>
        <TextInput id="participant-email" label="Email:" value={email} onChange={setEmail} style={{flex: 1}}/>

        <div style={{display: "flex", gap: "16px"}}>
        <OptionsSelect
          id="studyid"
          question="Study ID:"
          placeholder=""
          variant="primary"
          options={studyIds.map(i => ({value: i, text: i}))}
          value={studyId}
          onChange={v => setStudyId(v)}
          style={{flex: 1}} />
        <OptionsSelect
          id="siteid"
          question="Site ID:"
          placeholder=""
          variant="primary"
          options={siteIds.map(i => ({value: i, text: i}))}
          value={siteId}
          onChange={v => setSiteId(v)}
          style={{flex: 1}} />
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", gap: "32px", marginTop: "48px"}}>
        <PrimaryOutline onClick={onCancel}>Cancel</PrimaryOutline>
        <Button onClick={handleConfirm} disabled={!email || !studyId || !siteId}>Create user</Button>
      </div>
      {errorMsg && (
        <div style={{ color: "red", marginTop: "8px" }}>{errorMsg}</div>
      )}
    </div>
  )
}

const EditModalContent = ({
  checkPermissions,
  siteIds,
  studyIds,
  userId,
  email,
  siteId,
  studyId,
  active,
  onCancel,
  onChange,
  participantId, // Receive participantId as a prop
}) => {
  const [_email, _setEmail] = useState(email)
  const [_siteId, _setSiteId] = useState(siteId)
  const [_studyId, _setStudyId] = useState(studyId)
  const [_active, _setActive] = useState(active)
  const [errorMsg, setErrorMsg] = useState(null)

  const changed = _email !== email || active !== _active

  const onSave = async () => {
    setErrorMsg(null)

    if (!changed) {
      onCancel()
      return
    }

    try {
      if (_email !== email) {
        await sapClient.updateParticipantEmail({
          userId,
          email: _email,
        })
        toast.success("Invitation sent to new email")
      }

      if (active !== _active) {
        await sapClient.updateParticipantStatus({
          userId,
          active: _active,
        })
      }

      // Call onChange with updated participant object, including participantId
      onChange({
        userId,
        email: _email,
        siteId: _siteId,
        studyId: _studyId,
        active: _active,
        participantId,
      })
    } catch (error) {
      setErrorMsg(error.message)
      console.error(error)
    }
  }
  

  return (
    <div>
      <h2>Edit Participant</h2>

      <div style={{display: "flex", flexDirection: "column", gap: "32px", marginTop: "48px"}}>
        <div style={{display: "flex", gap: "16px"}}>
        <TextInput id="email" label="Email:" value={_email} onChange={_setEmail} disabled={!checkPermissions("admins.participants.edit")} style={{flex: 1}}/>
        <Protected needs={["admins.participants.activate"]}>
          <Toggle
            id="participant-active"
            label="Active:"
            checked={_active}
            onChange={(e) => _setActive(e.target.checked)} />
        </Protected>
        </div>
        <div style={{display: "flex", gap: "16px"}}>
          <OptionsSelect
            id="studyid"
            disabled={true}
            question="Study ID:"
            placeholder=""
            variant="primary"
            value={studyId}
            options={studyIds.map(i => ({value: i, text: i}))}
            style={{flex: 1}} />
          <OptionsSelect
            id="siteid"
            disabled={true}
            question="Site ID:"
            placeholder=""
            variant="primary"
            value={siteId}
            options={siteIds.map(i => ({value: i, text: i}))}
            style={{flex: 1}} />
        </div>
  
      <Protected needs={["admins.participants.resendInvite"]}>
      <Secondary
        disabled={!active}
        onClick={async () => {
          try {
            await sapClient.reSendEmailInvite({ userId, email })
            toast.success("Email invitation sent")
          } catch (e) {
            toast.error("Error sending email invitation")
            console.error(e)
          }
        }}
      >
        Resend Invitation Email Link
      </Secondary>
      </Protected>

      </div>

      <div style={{display: "flex", justifyContent: "center", gap: "32px", marginTop: "48px"}}>
        <PrimaryOutline onClick={onCancel}>Cancel</PrimaryOutline>
        <Button onClick={onSave} disabled={!_email || !changed}>Save Changes</Button>
      </div>
      {errorMsg && (
        <div style={{ color: "red", marginTop: "16px" }}>{errorMsg}</div>
      )}
    </div>
  )
}

export default function Participants() {
  const [studyIds, setStudyIds] = useState([])
  const [siteIds, setSiteIds] = useState([])
  const [participants, setParticipants] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [addModal, setAddModal] = useState({ showing: false })
  const [editModal, setEditModal] = useState({ showing: false, participantUserId: "" })
  const [searchParams, setSearchParams] = useSearchParams()

  const { permissions } = useSelector((state) => state.auth)

  const checkPermissions = (...needed) => {
    return needed.every((p) => permissions.includes(p))
  }

  const includeAssessment = checkPermissions("assessments.participantRecords.view")

  useEffect(() => {
    if (checkPermissions("admins.participants.view")) {
      sapClient.listParticipants({includeAssessment}).then((resp) => {
        const activeSites = resp.sites
          .filter(s => !s.disabled)
          .map((s) => s.siteId)
          .sort((a,b) => a - b)

        const activeStudies = resp.studies
          .filter(s => s.active)
          .map((s) => s.studyId)
          .sort((a,b) => a - b)

        setParticipants(resp.participants)
        setSiteIds(activeSites)
        setStudyIds(activeStudies)
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }, [])

  const participantBeingEdited = participants.find(
    (p) => p.userId === editModal.participantUserId
  )

  const { participantId } = participantBeingEdited || {}

  const columns = useMemo(
    () => {

      const extraCols = includeAssessment
      ? [{
          Header: "Initial Outcome",
          accessor: "initialOutcome",
          width: 100,
        },{
          Header: "Heath Survey Date",
          accessor: "lastAssessment",
          width: 100,
        }]
      : []

      return [
        {
          Header: "",
          accessor: "active",
          width: 20,
          Cell: ({value}) => (
            <>{!value ? <Deactivated /> : null}</>
          )
        },
        {
          Header: "Participant ID",
          accessor: "participantId",
          width: 150,
        },
        {
          Header: "Email Address",
          accessor: "email",
          width: 150,
        },
        ...extraCols,
        {
          Header: "Action",
          disableSortBy: true,
          Cell: (cell) => (
            <div style={{display: "flex", gap: "16px", alignItems: "center"}}>
            <Protected
              needs={[
                "assessments.participantRecords.view",
                "assessments.clinicianRecords.view",
                "assessments.clinicianRecords.add"
              ]}
              every={false}>
                <Button
                  style={{padding: "12px 12px", borderRadius: "12px"}}
                  onClick={() => {
                    setSearchParams({participant: cell.value})
                  }}>
                    View Records
                </Button>
            </Protected>
            <Protected needs={["admins.participants.edit", "admins.participants.activate"]} every={false}>
              <Secondary
                children="Edit"
                onClick={() => {
                  setEditModal({ showing: true, participantUserId: cell.value })
                }}
              />
            </Protected>
            </div>
          ),
          accessor: "userId",
          width: 140,
        },
      ]
    },
    [participants, permissions]
  )

  if (loading) {
    return <div>loading...</div>
  }

  const createParticipant = async ({ email, siteId, studyId }) => {
    try {
      const participant = await sapClient.createParticipant({
        email,
        siteId,
        studyId,
      })

      setParticipants((participants) => [...participants, participant])
      setAddModal({ showing: false })
    } catch (error) {
      throw error
    }
  }

  const participantBeingViewed = participants.find(p => {
    return p.userId === searchParams.get("participant")
  })

  return (
    <div className="page">
      <Modal
        isOpen={addModal.showing}
        onRequestClose={() => setAddModal({ showing: false })}
      >
        <AddModalContent
          studyIds={studyIds}
          siteIds={siteIds}
          onCancel={() => setAddModal({ showing: false })}
          onConfirm={createParticipant}
          error={errorMsg}
        />
      </Modal>
      <Modal
        isOpen={editModal.showing}
        onRequestClose={() =>
          setEditModal({ showing: false, participantUserId: "" })
        }
      >
        <EditModalContent
          checkPermissions={checkPermissions}
          studyIds={studyIds}
          siteIds={siteIds}
          onCancel={() =>
            setEditModal({ showing: false, participantUserId: "" })
          }
          onChange={(updated) => {
            setParticipants((participants) => {
              const i = participants.findIndex(
                (p) => p.userId === editModal.participantUserId
              )
              return [
                ...participants.slice(0, i),
                updated,
                ...participants.slice(i + 1),
              ]
            })
            setEditModal({ showing: false, participantUserId: "" })
          }}
          {...participantBeingEdited}
          participantId={participantId}
        />
      </Modal>
      {!participantBeingViewed ? (
      <div>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h1>Participants</h1>
          <Protected needs={["admins.participants.edit"]}>
            <PrimaryOutline
              style={{width: "initial"}}
              onClick={() => setAddModal({ showing: true })}
            >
              Add Participant
            </PrimaryOutline>
          </Protected>
        </div>
        <div style={{marginBottom: "16px"}}><Deactivated /> - Represents a deactivated account</div>
        <Table columns={columns} data={participants} />
      </div>
       ) : (
        <ViewRecordsForParticipant participant={participantBeingViewed} onClose={() => {
          setSearchParams({})
        }} />
       )}
    </div>
  )
}
