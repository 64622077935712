export default function E404() {
  return (
    <div className="page" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <div style={{textAlign: "center"}}>
        <h1>404</h1>
        <p>page not found</p>
      </div>
    </div>
  )
}
