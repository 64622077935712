import { createContext, useContext } from "react";

const ModalContext = createContext(() => {})

function useModal() {
  return useContext(ModalContext)
}

export default ModalContext
export { useModal }
