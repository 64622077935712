import { LoadingButton } from '../components/Button'
import Layout from './Layout'

export default function Playground() {
  return (    
    <Layout>
      <h1>hello</h1>
    </Layout>
  )
}
